// Global imports
import cn from 'classnames';
import {get, set, debounce} from 'lodash';
import {debtScenarioContexts} from 'ki-common/options/debt';
import {dateToShortDate} from 'ki-common/utils/dateHelpers';
import React, {useCallback, useEffect, useState} from 'react';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation, useParams} from 'react-router-dom';

// Project imports
import {reportsApi} from 'api';
import {fetchViewsByDataset} from 'api/viewsApi';
import {fetchBookmarks as fetchFundingAnalysisBookmarks} from 'api/fundingAnalysisApi';
import {fetchBookmarks as fetchDebtBookmarks} from 'api/debtExplorerApi';
import ContextSidebar from 'components/ContextSidebar';
import ContextIcons from 'components/ContextSidebar/icons';
import FlyoutCardList from 'components/FlyoutCardList';
import KiAppBar from 'components/KiAppBar';
import KiButton from 'components/KiButton';
import KiCardForecasting from 'components/KiCardForecasting';
import KiCardPreview from 'components/KiCardPreview';
import CardSettings from 'components/KiDataCardEditor/components/CardSettings';
import {KiIconCard} from 'components/KiIcons';
import KiInput from 'components/KiInput';
import KiModal from 'components/KiModal';
import KiProgressBar from 'components/KiProgressBar';
import {showSnackbar} from 'state/actions/Snackbar';
import KiSelect from '../../components/KiSelect';
import {useMergedState} from '../../utils/customHooks';

// Local imports
import CardSummary from './components/CardSummary';
import ReportOutputForm from './components/ReportOutputForm';
import {getDatasets, getDisplayCards, getFundingVehicles, getOutputTypes, getReport, getReportGroups} from './helpers';
import styles from './reports.theme.scss';

const initializedReport = {
	name: '',
	createdBy: '',
	updatedBy: '',
	settings: {
		datasetId: '',
		fundingVehicleId: '',
		scenarioType: 'lastApproved',
	},
	sections: [],
	templates: {},
};

const outputTypes = getOutputTypes();

const ReportDefinition = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const {id} = useParams();
	const isCopy = useLocation().pathname.includes('copy');
	const user = useSelector(state => state.user);
	const [report, setReport] = useState({name: ''});
	const [error, setError] = useState(null);
	const [nameError, setNameError] = useState('');
	const [displayCards, setDisplayCards] = useState([]);
	const [selectedCard, setSelectedCard] = useState(null);
	const [selectedTab, setSelectedTab] = useState(0);
	const [formCanSubmit, setFormCanSubmit] = useState(!!id);
	const [datasets, setDatasets] = useState([]);
	const [fundingVehicles, setFundingVehicles] = useState([]);
	const [reportGroups, setReportGroups] = useState([]);
	const [reportTemplateUpdated, setReportTemplateUpdated] = useState(false);
	const [wasUpdated, setWasUpdated] = useState(false);
	const [bookmarkLists, setBookmarkLists] = useState({
		asset: [],
		debt: [],
		funding: [],
	});
	const [isLoading, setIsLoading] = useState(false);

	// eslint-disable-next-line no-unused-vars
	const [formData, mergeFormData, setFormData] = useMergedState({
		name: '',
		settings: {
			datasetId: null,
			fundingVehicleId: null,
			reportGroupId: null,
			scenarioType: debtScenarioContexts[0].value,
			outputType: outputTypes[1].value,
		},
	});

	const validate = async (datasetId, name, reportGroupId) => {
		if (!name || !datasetId || !reportGroupId) {
			setFormCanSubmit(false);
			if (!name) {
				setNameError('Name is required');
			} else {
				setNameError('');
			}
			return;
		}

		const isNameUnique =
			(report._id && report.name === name) ||
			(await reportsApi.checkIfReportDefinitionNameIsUnique(reportGroupId, name));

		if (!isNameUnique) {
			setNameError('Name already in use');
			setFormCanSubmit(false);
			return;
		}

		setFormCanSubmit(true);
		setNameError('');
	};

	const validateDebounce = useCallback(debounce(validate, 700), []);

	const fetchData = async rawDatasets => {
		const rawReport = await getReport(id);
		if (isCopy) {
			delete rawReport._id;
			rawReport.name = `${report.name} Copy`;
		}

		const displayCards = await getDisplayCards(rawReport);
		const fundingVehicles = await getFundingVehicles(rawReport.settings.datasetId);
		fundingVehicles.unshift({
			name: 'All',
			_id: null,
		});
		const selectedDataset = rawDatasets.find(d => d.datasetId === rawReport.settings.datasetId);
		const selectedFundingVehicle = fundingVehicles.find(fv => fv._id === rawReport.settings.fundingVehicleId);
		mergeFormData({
			name: rawReport.name,
			settings: {
				datasetId: selectedDataset ? selectedDataset.datasetId : '',
				fundingVehicleId: selectedFundingVehicle ? selectedFundingVehicle._id : null,
				reportGroupId: rawReport.settings.reportGroupId ? rawReport.settings.reportGroupId : null,
				scenarioType: rawReport.settings.scenarioType
					? rawReport.settings.scenarioType
					: debtScenarioContexts[0].value,
				outputType: rawReport.settings.outputType ? rawReport.settings.outputType : outputTypes[1].value,
			},
		});

		setReport(rawReport);
		setDisplayCards(displayCards);
		setFundingVehicles(fundingVehicles);
		setReportGroups(await getReportGroups(rawReport.settings.datasetId));
	};

	useEffect(
		() => {
			(async () => {
				try {
					setIsLoading(true);
					const rawDatasets = await getDatasets();
					setDatasets(rawDatasets);
					if (!id) {
						setReport(initializedReport);
						setDatasets(rawDatasets);
					} else {
						await fetchData(rawDatasets);
					}
					setIsLoading(false);
				} catch (err) {
					setIsLoading(false);
					setError('There was a problem loading the report');
				}
			})();
		},
		[wasUpdated]
	);

	useEffect(
		() => {
			const selectedScenario = debtScenarioContexts.find(
				option => option.value === get(report, 'settings.scenarioType', initializedReport.settings.scenarioType)
			);
			mergeFormData({
				settings: {
					scenarioType: selectedScenario.value,
				},
			});
		},
		[report]
	);

	useEffect(
		() => {
			const datasetId = report?.settings?.datasetId;
			if (datasetId) {
				const getBookmarks = async () => {
					// TODO make it one request instead of 3. Requires new endpoint.
					const [asset, debt, funding] = await Promise.all([
						fetchViewsByDataset(datasetId),
						fetchDebtBookmarks(datasetId),
						fetchFundingAnalysisBookmarks(datasetId),
					]);
					setBookmarkLists({asset, debt, funding});
				};
				getBookmarks();
			}
		},
		[report?.settings?.datasetId]
	);

	useEffect(
		() => {
			(async () => {
				if (!report.settings || !report.sections.length) return; // no reason to fetch display cards
				//if (!report.settings || !selectedDataset) return;
				const displayCards = await getDisplayCards(report);
				setDisplayCards(displayCards);
			})();
		},
		[report.sections]
	);

	useEffect(
		() => {
			(async () => {
				if (reportTemplateUpdated) {
					const report = await getReport(id);
					setReport(report);
				}
			})();
		},
		[reportTemplateUpdated]
	);

	const onDatasetChange = async dataset => {
		mergeFormData({
			settings: {
				datasetId: dataset.datasetId,
				fundingVehicleId: null,
			},
		});
		const updatedFV = await getFundingVehicles(dataset.datasetId);
		updatedFV.unshift({
			name: 'All',
			_id: null,
		});
		setFundingVehicles(updatedFV);
		setReportGroups(await getReportGroups(dataset.datasetId));
		setReport({...report, sections: [], settings: {...report.settings, datasetId: dataset.datasetId}});
		await validate(dataset.datasetId, formData.name, formData.settings.reportGroupId);
	};

	const onReportNameChange = value => {
		mergeFormData({name: value});
		validateDebounce(formData.settings.datasetId, value, formData.settings.reportGroupId);
	};

	const onReportGroupChange = async value => {
		mergeFormData({
			settings: {
				reportGroupId: value._id,
			},
		});
		await validate(formData.settings.datasetId, formData.name, value._id);
	};

	const addCardToReport = (id, name) => {
		// do not allow cards to be duplicated in a report
		if (report.sections.map(r => r._id).includes(id)) {
			return;
		}
		const newSection = {
			_id: id,
			type: 'card',
			title: name,
		};
		const sectionList = report.sections.slice();
		sectionList.push(newSection);
		const updatedReport = {
			...report,
			sections: sectionList,
		};
		setReport(updatedReport);
	};

	const removeCardFromReport = cardId => {
		const sectionList = report.sections.slice();
		const newList = sectionList.filter(section => {
			return section._id != cardId;
		});
		const updatedReport = {
			...report,
			sections: newList,
		};
		setReport(updatedReport);
	};

	const getUpdatedReport = () => {
		const updatedReport = {
			...report,
			name: formData.name,
			createdBy: report.createdBy || user.userId,
			updatedBy: user.userId,
			settings: {
				...report.settings,
				...formData.settings,
			},
		};
		return updatedReport;
	};

	const onSubmitClick = async () => {
		setFormCanSubmit(false);

		const updatedReport = getUpdatedReport();
		reportsApi
			.saveReportDefinition(updatedReport)
			.then(() => {
				dispatch(showSnackbar(`Report saved`));
				if (updatedReport.settings.reportGroupId) {
					history.push(`/reports/${updatedReport.settings.reportGroupId}`);
				} else {
					history.push(`/reports`);
				}
			})
			.catch(() => {
				dispatch(showSnackbar(`Error saving report definition`));
			});
	};

	const getReportForPreview = () => {
		// Since we dont have a date context adjust the statement date by -1 day
		const fakeJsStatementDate = new Date();
		fakeJsStatementDate.setDate(fakeJsStatementDate.getDate() - 1); // Method sets day of month
		const fakeStatementDate = dateToShortDate(fakeJsStatementDate);

		const updatedReport = getUpdatedReport();
		set(updatedReport, 'settings.statementDate', fakeStatementDate);
		return updatedReport;
	};

	const onCancelClick = () => {
		if (report.settings.reportGroupId) {
			history.push(`/reports/${report.settings.reportGroupId}`);
		} else {
			history.push(`/reports`);
		}
	};

	const onReportTemplateSaved = () => {
		setReportTemplateUpdated(true);
	};

	const flyoutManageReports = () => {
		return (
			<div className="context-sidebar-panel-flex">
				{report._id ? (
					<ReportOutputForm
						report={report}
						datasetsList={datasets}
						fundingVehicleList={fundingVehicles}
						user={user}
						onReportTemplateSaved={onReportTemplateSaved}
					/>
				) : (
					<div className={styles.outputFormInfo}>
						Output settings are available once the report has been saved
					</div>
				)}
			</div>
		);
	};

	const deleteSection = index => {
		const sectionList = report.sections.slice();
		sectionList.splice(index, 1);
		const updatedReport = {
			...report,
			sections: sectionList,
		};
		setReport(updatedReport);
	};

	const reorder = (list, startIndex, endIndex) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);

		return result;
	};

	const onDragEnd = result => {
		if (!result.destination) {
			return;
		}

		if (result.destination.index === result.source.index) {
			return;
		}

		const sections = reorder(report.sections, result.source.index, result.destination.index);

		setReport({...report, sections: sections});
	};

	const handleSetSelectedCard = (card, tabNumber) => {
		setSelectedCard(card);
		setSelectedTab(tabNumber);
	};

	if (!formData.settings.datasetId && !report) return null;

	if (isLoading) {
		return <KiProgressBar />;
	}

	const renderDraggable = (key, idx, section, displayCard) => {
		// If the card has an error return rather than break
		if (displayCard.error) {
			return false;
		}

		const {card} = displayCard;
		let bookmarks = [];
		let bookmarkId;

		switch (card.settings.type) {
			case 'tabular':
				bookmarks = bookmarkLists.asset;
				bookmarkId = card.bookmarkId;
				break;
			case 'fundingAnalysisView':
				bookmarks = bookmarkLists.funding;
				bookmarkId = card.settings.fundingAnalysisBookmarkId;
				break;
			case 'debtView':
				bookmarks = bookmarkLists.debt;
				bookmarkId = card.settings.debtBookmarkId;
				break;
			case 'projections':
				break;
		}

		const bookmark = bookmarks.find(boo => boo._id === bookmarkId);

		return (
			<Draggable key={key} draggableId={key} index={idx}>
				{provided => (
					<div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
						<CardSummary
							index={idx}
							section={section}
							displayCard={displayCard}
							bookmark={bookmark}
							setSelectedCard={handleSetSelectedCard}
							deleteSection={deleteSection}
							allowEdit={true}
						/>
					</div>
				)}
			</Draggable>
		);
	};

	return (
		<div className="container-wrapper">
			<div className="container-body">
				<KiAppBar>
					<div className="top-bar-breadcrumb">
						<h1
							className="link"
							onClick={() => {
								if (report.settings.reportGroupId) {
									history.push(`/reports/${report.settings.reportGroupId}`);
								} else {
									history.push(`/reports`);
								}
							}}
						>
							Reports
						</h1>
						<h1>{` → ${id ? report.name : 'New Report Configuration'}`}</h1>
					</div>
				</KiAppBar>
				{error ? (
					<div className="ki-panel">
						<div className={styles.errorMsg}>{error}</div>
					</div>
				) : (
					<div className="ki-panel">
						<div className={styles.reportDefinitionForm}>
							<div className={styles.formGroup}>
								<div className={styles.controlWrapper}>
									<KiInput
										type="text"
										name="name"
										label="Report Name"
										value={formData.name}
										onChange={value => onReportNameChange(value)}
										error={nameError}
									/>
								</div>
							</div>
							<div className={styles.formGroup}>
								<div className={cn(styles.controlWrapper, styles.selectWrapper)}>
									<span className="theme-label">Dataset</span>
									<KiSelect
										classNamePrefix="aut-select"
										value={datasets.find(item => item.datasetId === formData.settings.datasetId)}
										isClearable={false}
										options={datasets}
										onChange={value => onDatasetChange(value)}
										getOptionLabel={option => option.name}
										getOptionValue={option => option.datasetId}
									/>
								</div>

								<div className={cn(styles.controlWrapper, styles.selectWrapper)}>
									<span className="theme-label">Report Group</span>
									<KiSelect
										classNamePrefix="aut-select"
										value={reportGroups.find(item => item._id === formData.settings.reportGroupId)}
										isClearable={false}
										options={reportGroups}
										onChange={onReportGroupChange}
										getOptionLabel={option => option.name}
										getOptionValue={option => option._id}
									/>
								</div>

								<div className={cn(styles.controlWrapper, styles.selectWrapper)}>
									<span className="theme-label">Default Funding Vehicle</span>
									<KiSelect
										classNamePrefix="aut-select"
										closeOnSelect={true}
										isClearable={true}
										onChange={value =>
											mergeFormData({
												settings: {
													fundingVehicleId: value ? value._id : null,
												},
											})
										}
										options={fundingVehicles}
										value={fundingVehicles.find(
											item => item._id === formData.settings.fundingVehicleId
										)}
										getOptionLabel={option => option.name}
										getOptionValue={option => option._id}
									/>
								</div>

								<div className={cn(styles.controlWrapper, styles.selectWrapper)}>
									<span className="theme-label">Default Scenario Context</span>
									<KiSelect
										classNamePrefix="aut-select"
										closeOnSelect={true}
										isClearable={false}
										onChange={value =>
											mergeFormData({
												settings: {
													scenarioType: value.value,
												},
											})
										}
										options={debtScenarioContexts}
										value={debtScenarioContexts.find(
											item => item.value === formData.settings.scenarioType
										)}
										getOptionLabel={option => option.label}
										getOptionValue={option => option.value}
									/>
								</div>

								<div className={cn(styles.controlWrapper, styles.selectWrapper)}>
									<span className="theme-label">Default Output Type</span>
									<KiSelect
										classNamePrefix="aut-select"
										closeOnSelect={true}
										isClearable={false}
										onChange={value =>
											mergeFormData({
												settings: {
													outputType: value.value,
												},
											})
										}
										options={outputTypes}
										value={outputTypes.find(item => item.value === formData.settings.outputType)}
									/>
								</div>
							</div>

							<p className={styles.hint}>
								In order to Save a report, please select at least one card from the Card Management
								menu.
							</p>

							{formData.settings.datasetId &&
							report &&
							report.sections?.length &&
							displayCards &&
							displayCards.length ? (
								<React.Fragment>
									<DragDropContext onDragEnd={onDragEnd}>
										<Droppable droppableId="list">
											{provided => (
												<div
													ref={provided.innerRef}
													{...provided.droppableProps}
													className={styles.cardList}
												>
													{report.sections.map((section, idx) => {
														const displayCard = displayCards.find(
															c => c.section._id === section._id
														);
														const key = `${idx}_${section._id}`;
														return displayCard
															? renderDraggable(key, idx, section, displayCard)
															: null;
													})}
													{provided.placeholder}
												</div>
											)}
										</Droppable>
									</DragDropContext>
									<KiModal
										active={!!selectedCard}
										header={get(selectedCard, 'name')}
										className="ki-modal"
										bodyClassName={styles.modalBody}
										onClose={() => handleSetSelectedCard(null, 0)}
										showCloseIcon={true}
									>
										{!!selectedCard &&
											selectedTab === 0 &&
											get(selectedCard, 'settings.type') !== 'projections' && (
												<KiCardPreview
													card={selectedCard}
													reportDefinition={getReportForPreview()}
												/>
											)}
										{!!selectedCard &&
											selectedTab === 0 &&
											get(selectedCard, 'settings.type') === 'projections' && (
												<KiCardForecasting card={selectedCard} />
											)}
										{!!selectedCard &&
											selectedTab === 1 && (
												<CardSettings
													card={selectedCard}
													closeModal={() => handleSetSelectedCard(null, 0)}
													wasUpdated={wasUpdated}
													setWasUpdated={setWasUpdated}
												/>
											)}
									</KiModal>
								</React.Fragment>
							) : null}

							<p className={styles.actions}>
								<KiButton flat primary type="submit" label="Cancel" onClick={onCancelClick} />
								<KiButton
									type="submit"
									label="Save"
									raised
									primary
									disabled={!(formCanSubmit && report.sections?.length)}
									onClick={onSubmitClick}
								/>
							</p>
						</div>
					</div>
				)}
			</div>
			{report &&
				report.settings &&
				formData.settings.datasetId && (
					<ContextSidebar
						items={[
							{
								name: 'Card Management',
								icon: <KiIconCard />,
								element: (
									<FlyoutCardList
										datasetId={formData.settings.datasetId}
										fundingVehicleId={report.settings.fundingVehicleId}
										addCardToReport={addCardToReport}
										removeCardFromReport={removeCardFromReport}
									/>
								),
							},
							{
								name: 'Output Settings',
								icon: <ContextIcons.MaterialIcon name="cloud_download" />,
								element: flyoutManageReports(),
							},
						]}
					/>
				)}
		</div>
	);
};

export default ReportDefinition;
