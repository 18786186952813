import PropTypes from 'prop-types';
import React, {Component} from 'react';
import KiConfirmModal from 'components/KiConfirmModal';
import ConstraintForm from 'components/ConstraintForm';
import _ from 'lodash';
import styles from './settingsRenderForm.theme.scss';

export const ConfirmMessage = props => {
	const {setting, action = 'alter'} = props;
	if (!setting) return null;

	return (
		<div>
			<h2>{`Are you sure you want to ${action} the following setting?`}</h2>
			<ul>
				<li key={setting._id}>{setting.name}</li>
			</ul>
		</div>
	);
};
ConfirmMessage.propTypes = {
	setting: PropTypes.object,
	action: PropTypes.string,
};

export class SettingsRenderForm extends Component {
	static defaultProps = {
		settings: [],
		tbCohortColumns: [],
		eligibleColumns: [],
		type: '',
		readOnly: false,
		allowIgnore: false,
		hideConstraintType: false,
		markNonCovenantsInactive: false,
		allowedConstraintTypes: [],
		item: {},
		index: '',
		layoutClass: '',
		showControls: true,
	};

	static propTypes = {
		settings: PropTypes.array,
		fundingVehicleId: PropTypes.string,
		updateFundingVehicleSetting: PropTypes.func,
		deleteFundingVehicleSettings: PropTypes.func,
		addFundingVehicleSetting: PropTypes.func,
		tbCohortColumns: PropTypes.array,
		eligibleColumns: PropTypes.array,
		allowedConstraintTypes: PropTypes.array,
		isGrouped: PropTypes.bool,
		item: PropTypes.object,
		itemId: PropTypes.string,
	};

	constructor(props) {
		super(props);
		this.constraintFormHook = React.createRef();
	}

	state = {
		newConstraintRowType: 'ccExclude',
		isDeleteConfirmActive: false,
	};

	/*
	  BLD-22353 (release-4.6.0) prevents re-rendering form from random upstream prop changes (eg: submission triggers)
	  unless they actually change props that affect this form's props.
	 */
	shouldComponentUpdate(nextProps, nextState, nextContext) {
		return !_.isEqual(nextProps, this.props) || nextState !== this.state || nextContext !== this.context;
	}

	// Delete functions
	showDeleteConfirmModal = () => {
		this.setState({
			isDeleteConfirmActive: true,
		});
	};

	hideDeleteConfirmModal = () => {
		this.setState({
			isDeleteConfirmActive: false,
		});
	};

	deleteSelectedRows = () => {
		this.props.deleteFundingVehicleSettings([this.props.item]);
		this.setState({isDeleteConfirmActive: false});
	};

	setNewConstraintRowType = rowType => {
		this.setState({newConstraintRowType: rowType});
	};

	upsertConstraint = constraint => {
		if (constraint._id) {
			this.props.updateFundingVehicleSetting(constraint);
		} else {
			this.props.addFundingVehicleSetting(constraint);
		}
	};

	render() {
		const {isDeleteConfirmActive} = this.state;
		const isEdit = this.props.itemId ? true : false;
		const renderItem = isEdit
			? this.props.item
			: {
					rowType:
						this.props.allowedConstraintTypes.length &&
						!this.props.allowedConstraintTypes.includes(this.state.newConstraintRowType)
							? this.props.allowedConstraintTypes[0]
							: this.state.newConstraintRowType,
					name: '',
					fundingVehicleId: this.props.fundingVehicleId,
					applyConstraint: true,
					applyCovenant: false,
			  };
		return (
			<div className={styles.root}>
				<ConstraintForm
					constraintData={renderItem}
					fundingVehicleId={this.props.fundingVehicleId}
					fvConstraints={this.props.settings}
					allColumns={this.props.tbCohortColumns}
					eligibleColumns={this.props.eligibleColumns}
					onSaveMethod={this.upsertConstraint}
					onDeleteMethod={() => this.showDeleteConfirmModal(renderItem)}
					isReadOnly={false}
					isGroupBy={this.props.isGrouped}
					isFundingAnalysisConstraint={false}
				/>
				<KiConfirmModal
					header="Delete Selected Settings"
					message={<ConfirmMessage setting={renderItem} action="delete" />}
					acceptFunc={this.deleteSelectedRows}
					rejectFunc={this.hideDeleteConfirmModal}
					acceptLabel="Delete"
					rejectLabel="Cancel"
					active={isDeleteConfirmActive}
				/>
			</div>
		);
	}
}

export default SettingsRenderForm;
